<template>
  <app-card v-if="task" card-class="h-auto">
    <template #body>
      <div class="d-flex">
        <!--begin: Info-->
        <div class="flex-grow-1">
          <!--begin: Title-->
          <div class="d-flex align-items-center justify-content-between flex-wrap">
            <div class="mr-3">
              <!--begin::Name-->
              <a href="#"
                 class="d-flex align-items-center text-dark text-hover-primary font-size-h5 font-weight-bold mr-3">
                {{ task.task_title }}
              </a>
              <!--end::Name-->
            </div>
          </div>
          <!--end: Title-->
          <!--begin: Content-->
          <b-row class="align-items-center">
            <b-col md="6">
              <div class="font-weight-bold text-dark-50 py-5 py-lg-2 mr-5">
                {{ task.task_description }}
              </div>
            </b-col>
          </b-row>
          <!--end: Content-->
        </div>
        <!--end: Info-->
      </div>
      <div class="separator separator-solid my-7"></div>

      <div class="task-additional-data d-flex align-items-center justify-content-between">
        <div class="child">
          <div class="d-flex align-items-center flex-lg-fill my-1">
              <span class="icon mr-4">
                <inline-svg src="/media/svg/icons/Home/Building.svg"/>
              </span>
            <div class="d-flex flex-column text-dark-75">
              <span class="font-weight-bolder font-size-sm">{{ $t('label.complex') }}</span>
              <span class="font-weight-bolder font-size-h5">{{ task.task_complex }}</span>
            </div>
          </div>
        </div>
        <div class="child">
          <div class="d-flex align-items-center flex-lg-fill my-1">
              <span class="icon mr-4">
                <inline-svg src="/media/svg/icons/Layout/Layout-4-blocks.svg"/>
              </span>
            <div class="d-flex flex-column text-dark-75">
              <span class="font-weight-bolder font-size-sm">{{ $t('label.block') }}</span>
              <span class="font-weight-bolder font-size-h5">{{ task.task_block }}</span>
            </div>
          </div>
        </div>
        <div class="child">
          <div class="d-flex align-items-center flex-lg-fill my-1">
            <span class="icon mr-4">
              <inline-svg src="/media/svg/icons/Layout/Layout-3d.svg"/>
            </span>
            <div class="d-flex flex-column text-dark-75">
              <span class="font-weight-bolder font-size-sm">{{ $t('label.location_type') }}</span>
              <span class="font-weight-bolder font-size-h5">{{ task.task_location_type }}</span>
            </div>
          </div>
        </div>
        <div class="child">
          <div class="d-flex align-items-center flex-lg-fill my-1">
              <span class="icon mr-4">
               <inline-svg src="/media/svg/icons/Home/Home.svg"/>
              </span>
            <div class="d-flex flex-column text-dark-75">
              <span class="font-weight-bolder font-size-sm">{{ $t('label.location') }}</span>
              <span class="font-weight-bolder font-size-h5">{{ task.task_location }}</span>
            </div>
          </div>
        </div>
        <div class="child">
          <div class="d-flex align-items-center flex-lg-fill my-1">
              <span class="icon mr-4">
               <inline-svg src="/media/svg/icons/Code/Time-schedule.svg"/>
              </span>
            <div class="d-flex flex-column text-dark-75">
              <span class="font-weight-bolder font-size-sm">{{ $t('label.created_at') }}</span>
              <span class="font-weight-bolder font-size-h5">{{ task.task_created_at }}</span>
            </div>
          </div>
        </div>
        <div class="child">
          <div class="d-flex align-items-center flex-lg-fill my-1">
              <span class="icon mr-4">
               <inline-svg src="/media/svg/icons/Home/Timer.svg"/>
              </span>
            <div class="d-flex flex-column text-dark-75">
              <span class="font-weight-bolder font-size-sm">{{ $t('label.deadline') }}</span>
              <span class="font-weight-bolder text-danger font-size-h5">{{ task.task_deadline }}</span>
            </div>
          </div>
        </div>
      </div>
    </template>
  </app-card>
</template>

<script>
import AppCard from '@/components/AppCard';

export default {
  components: {
    AppCard
  },
  props: {
    task: {
      type: Object,
      default: null
    }
  }
}
</script>