<template>
  <div class="form-group">
    <div v-if="label" class="form-group__label-wrapper" :class="{ required }">
      <label class="form-group__label">{{ label }}</label>
    </div>
    <b-form-datepicker
        class="app-datepicker"
        :class="{error: withError}"
        :value="value"
        :placeholder="placeholder"
        :disabled="disabled"
        :date-format-options="{year: 'numeric', month: 'numeric', day: 'numeric'}"
        :min="minDate"
        :max="maxDate"
        format="dd.mm.YYYY"
        hide-header
        @click.prevent
        @input.self="$emit('change', $event)"
    />
    <span v-if="helpMessage" class="form-text text-muted">{{ helpMessage }}</span>
    <p v-if="withError" class="error-message text-danger">{{ errorMessage }}</p>
  </div>
</template>

<script>
import i18n from '@/core/plugins/vue-i18n';

export default {
  props: {
    label: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    },
    value: {
      default: null
    },
    minDate: {
      default: null
    },
    maxDate: {
      default: null
    },
    required: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    readOnly: {
      type: Boolean,
      default: false
    },
    withError: {
      type: Boolean,
      default: false
    },
    errorMessage: {
      type: String,
      default: i18n.t('error.field_is_required')
    },
    helpMessage: {
      type: String,
      default: ''
    },
  }
};
</script>

<style lang="scss" scoped>
.form-group {
  position: relative;
}

.form-group__label-wrapper {
  &.required {
    position: relative;
    display: inline-block;

    :after {
      content: "*";
      position: absolute;
      color: #f64e60;
      font-weight: bold;
      right: -10px;
    }
  }
}

.error-message {
  position: absolute;
  bottom: -32px;
  left: 0;
  font-size: 0.9rem;
}

.app-input {
  height: 38px;

  &[disabled="disabled"] {
    background-color: #F3F6F9;
  }
}

.app-input.error {
  border-color: #f64e60;
}
</style>
