<template>
  <b-modal :id="id" hide-footer centered no-close-on-backdrop>
    <template #modal-title>
      {{ title }}
    </template>
    <div class="d-block text-center">
      <slot name="app-modal-body"></slot>
    </div>
    <div class="d-flex justify-content-center mt-10">
      <b-button v-if="!hideCancel" class="mr-3" variant="outline-secondary" @click="cancel">{{ cancelText }}</b-button>
      <b-button v-if="!hideOk" :variant="OkVariant" @click="ok">{{ OkText }}</b-button>
    </div>
  </b-modal>
</template>

<script>
import i18n from '@/core/plugins/vue-i18n';

export default {
  props: {
    id: {
      type: String,
      default: 'app-modal'
    },
    title: {
      type: String,
      default: ''
    },
    hideCancel: {
      type: Boolean,
      default: false
    },
    hideOk: {
      type: Boolean,
      default: false
    },
    cancelText: {
      type: String,
      default: i18n.t('btn.cancel')
    },
    OkText: {
      type: String,
      default: i18n.t('btn.ok')
    },
    OkVariant: {
      type: String,
      default: 'success'
    }
  },
  methods: {
    cancel() {
      this.$bvModal.hide(this.id);
      this.$emit('cancel');
    },
    ok() {
      this.$bvModal.hide(this.id);
      this.$emit('ok');
    },
  }
}
</script>