<template>
  <div>
    <div class="d-flex justify-content-end mt-10">
      <b-button variant="outline-white" class="d-block w-100" v-b-modal.create-act-modal>
        {{ $t('btn.create_act') }}
      </b-button>
    </div>

    <task-card :task="task" class="mt-5"/>
    <acts-list :token="token" class="mt-10"/>


    <app-modal
        id="create-act-modal"
        :title="$t('label.form')"
        :ok-text="$t('btn.create')"
        ok-variant="success"
        @ok="createAct"
    >
      <template #app-modal-body>
        <act-form @change="form = $event"/>
      </template>
    </app-modal>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';
import ActForm from './components/ActForm';
import ActsList from './components/ActsList';
import AppModal from '@/components/modals/AppModal';
import TaskCard from './components/TaskCard';

export default {
  components: {
    ActForm,
    ActsList,
    AppModal,
    TaskCard
  },
  data: () => ({
    form: {},
    token: null
  }),
  beforeMount() {
    this.token = this.$route.params.token;
    this.$store.dispatch('guestActsStore/GET_TASK', this.token);
  },
  computed: {
    ...mapGetters({
      task: 'guestActsStore/TASK'
    })
  },
  methods: {
    createAct() {
      this.$store.dispatch('guestActsStore/CREATE_ACT', {token: this.token, data: this.form});
    }
  }
}
</script>