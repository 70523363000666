<template>
  <div class="form-group">
    <div v-if="label" class="form-group__label-wrapper" :class="{ required }">
      <label class="form-group__label">{{ label }}</label>
    </div>
    <span v-if="password" class="svg-icon toggle-type cursor-pointer" @click="showPassword = !showPassword">
      <inline-svg :src="`/media/svg/icons/General/${showPassword ? 'Visible' : 'Hidden'}.svg`"/>
    </span>
    <b-form-input
        class="app-input"
        :class="{error: withError}"
        :value="value"
        :disabled="disabled"
        :readonly="readOnly"
        :placeholder="placeholder"
        trim
        :type="password && !showPassword ? 'password' : 'text'"
        @input="$emit('input', $event)"
    ></b-form-input>
    <span v-if="helpMessage" class="form-text text-muted">{{ helpMessage }}</span>
    <p v-if="withError" class="error-message text-danger">{{ errorMessage }}</p>
  </div>
</template>

<script>
import i18n from '@/core/plugins/vue-i18n';

export default {
  props: {
    label: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    },
    value: {
      default: ''
    },
    required: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    password: {
      type: Boolean,
      default: false
    },
    readOnly: {
      type: Boolean,
      default: false
    },
    withError: {
      type: Boolean,
      default: false
    },
    errorMessage: {
      type: String,
      default: i18n.t('error.field_is_required')
    },
    helpMessage: {
      type: String,
      default: ''
    }
  },
  data: () => ({
    showPassword: false
  })
};
</script>

<style lang="scss" scoped>
.form-group {
  position: relative;

  .toggle-type {
    position: absolute;
    right: 10px;
    top: 33px;
  }
}

.form-group__label-wrapper {
  &.required {
    position: relative;
    display: inline-block;

    :after {
      content: "*";
      position: absolute;
      color: #f64e60;
      font-weight: bold;
      right: -10px;
    }
  }
}

.error-message {
  position: absolute;
  bottom: -32px;
  left: 0;
  font-size: 0.9rem;
}

.app-input {
  height: 38px;

  &[disabled="disabled"] {
    background-color: #F3F6F9;
  }
}

.app-input.error {
  border-color: #f64e60;
}
</style>
