<template>
  <div>
    <b-row>
      <b-col md="12">
        <app-input
            :label="$t('label.master')"
            v-model="form.master"
            :placeholder="$t('placeholder.enter_master_name')"
        />
      </b-col>
    </b-row>
    <b-row>
      <b-col md="12">
        <app-input
            :label="$t('label.assistant')"
            v-model="form.assistant"
            :placeholder="$t('placeholder.enter_assistant_name')"
        />
      </b-col>
    </b-row>
    <b-row>
      <b-col md="12">
        <app-textarea
            :label="$t('label.error_description')"
            v-model="form.error_description"
            :placeholder="$t('placeholder.enter_error_description')"
            rows="5"
        />
      </b-col>
    </b-row>
    <b-row>
      <b-col md="12">
        <app-textarea
            :label="$t('label.job_description')"
            v-model="form.job_description"
            :placeholder="$t('placeholder.enter_job_description')"
            rows="5"
        />
      </b-col>
    </b-row>
    <b-row>
      <b-col md="12">
        <app-textarea
            :label="$t('label.description_by_master')"
            v-model="form.description_by_master"
            :placeholder="$t('placeholder.enter_description_by_master')"
            rows="5"
        />
      </b-col>
    </b-row>
    <b-row>
      <b-col md="12" class="form-group">
        <div class="form-group__label-wrapper">
          <label class="form-group__label">{{ $t('label.image') }}</label>
        </div>
        <b-form-file
          v-model="form.document"
          :placeholder="$t('placeholder.choose_file_or_drop_it_here')"
          :drop-placeholder="$t('placeholder.drop_file_here')"
          accept="image/*"
        ></b-form-file>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="12">
        <app-datepicker
            :label="$t('label.arrival_at')"
            :placeholder="$t('placeholder.choose_arrival_at_date')"
            :value="form.arrival_at"
            :min-date="new Date()"
            @change="form.arrival_at = $event"
        />
      </b-col>
    </b-row>
    <b-row>
      <b-col md="12">
        <app-datepicker
            :label="$t('label.finished_at')"
            :placeholder="$t('placeholder.choose_finished_at_date')"
            :value="form.finished_at"
            :min-date="new Date()"
            @change="form.finished_at = $event"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import AppDatepicker from '@/components/form-groups/AppDatepicker';
import AppInput from '@/components/form-groups/AppInput';
import AppTextarea from '@/components/form-groups/AppTextarea';

export default {
  components: {
    AppDatepicker,
    AppInput,
    AppTextarea,
  },
  data: () => ({
    form: {
      master: null,
      assistant: null,
      error_description: '',
      job_description: '',
      description_by_master: '',
      document: null,
      arrival_at: null,
      finished_at: null
    }
  }),
  watch: {
    form: {
      handler(data) {
        let formData = new FormData();
        formData.append('master', data.master);
        formData.append('assistant', data.assistant);
        formData.append('error_description', data.error_description);
        formData.append('job_description', data.job_description);
        formData.append('description_by_master', data.description_by_master);
        formData.append('document', data.document);
        formData.append('arrival_at', data.arrival_at);
        formData.append('finished_at', data.finished_at);

        this.$emit('change', formData);
      },
      deep: true
    }
  }
}
</script>