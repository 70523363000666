import { render, staticRenderFns } from "./AppTextarea.vue?vue&type=template&id=249e175c&scoped=true&"
import script from "./AppTextarea.vue?vue&type=script&lang=js&"
export * from "./AppTextarea.vue?vue&type=script&lang=js&"
import style0 from "./AppTextarea.vue?vue&type=style&index=0&id=249e175c&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "249e175c",
  null
  
)

export default component.exports