<template>
  <div class="card card-custom card-stretch" v-bind:class="classes">
    <div class="card-header" v-bind:class="headClass" v-if="hasTitleSlot || title">
      <div class="card-title">
        <slot name="title" v-if="hasTitleSlot"></slot>
        <h3 class="card-label" v-if="!hasTitleSlot">{{ title }}</h3>
      </div>
      <div class="card-toolbar">
        <slot name="toolbar"></slot>
      </div>
    </div>
    <div class="card-body" :class="[bodyClass]">
      <slot name="body"></slot>
    </div>
    <div class="card-footer" v-if="hasFootSlot">
      <slot name="foot"></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: String,
    headSize: String,
    fluidHeight: Boolean,
    fluidHalfHeight: Boolean,
    headOverlay: Boolean,
    cardClass: String,
    headClass: String,
    bodyClass: String,
    bodyFit: Boolean,
    bodyFluid: Boolean,
    example: Boolean
  },
  computed: {
    classes() {
      const cls = {
        "example example-compact": this.example,
        "height-fluid": this.fluidHeight,
        "height-fluid-half": this.fluidHalfHeight,
        "head-overlay": this.headOverlay
      };

      cls[this.headSizeClass] = this.headSizeClass;

      // append extra classes
      if (this.cardClass) {
        cls[this.cardClass] = true;
      }

      return cls;
    },
    hasTitleSlot() {
      return !!this.$slots["title"];
    },
    hasFootSlot() {
      return !!this.$slots["foot"];
    },
    headSizeClass() {
      if (this.headSize) {
        return `head-${this.headSize}`;
      }
      return false;
    }
  }
};
</script>
